<template>
    <div class="helping">
      <div class="base_w">
        <div class="title">{{info.title}}</div>
		    <div v-html="info.content"></div>
      </div>
    </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
    components: {},
    data() {
        return {
          id:'',
				  info:{},
        };
    },
    computed: {},
    watch: {},
    methods: {
      getData() {
        let data = {
          id:  this.$route.query.id
        }
        apiUrl.lsProbleminfo(data).then((res) => {
          this.info = res.data;
        })

			},
    },
    created() {},
    mounted() {
      this.getData();
    },
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    }
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.helping{
  background-color:  #F8F8F8;
  padding: 20px 0 33px;
  .base_w{
    min-height: 650px;
    background: #FFFFFF;
    border-radius: 13px;
    padding: 17px 20px 84px;
    .title {
			// font-size: 16px;
			// font-weight: 500;
			// color: #0A0B10;
			// margin: 0 0 30rpx;
      font-size: 23px;
      font-weight: 500;
      color: #333333;
      text-align: center;
      padding-top: 20px;
		}
  }
}

</style>